import type { FinanceFilters } from '@models/finance'
import { keyToUrl } from '@tools/common'
import { omit } from 'lodash'

export const financesKeys = {
  root: ['finances'] as const,
  list: (filters?: FinanceFilters) =>
    [
      ...financesKeys.root,
      omit(filters, ['take', 'cursor']),
      { take: filters?.take, cursor: filters?.cursor },
    ] as const,
  addFinances: () => [...financesKeys.root] as const,
}

export const financesUrls = {
  root: keyToUrl(financesKeys.root),
  list: (filters?: FinanceFilters) => keyToUrl(financesKeys.list(filters)),
  addFinances: keyToUrl(financesKeys.addFinances()),
}
