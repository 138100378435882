import httpClient from '@api/httpClient'
import type { Route, RouteFilters } from '@models/route'
import { routesUrls } from './constants'

const getRoutes = async (filters: RouteFilters) => {
  const { data } = await httpClient.get<Route[]>(routesUrls.root, {
    params: filters,
  })
  return data
}

const finishRoute = async ({ routeId }: { routeId: string }) => {
  await httpClient.post(routesUrls.finishRoute(routeId))
}

const processPlan = async ({
  companyId,
  depotUserDefinedId,
}: {
  companyId: string
  depotUserDefinedId: string
}) => {
  await httpClient.post(routesUrls.processPlan, {
    companyId,
    depotUserDefinedId,
    withoutPlanDeleting: true,
  })
}

export default {
  getRoutes,
  finishRoute,
  processPlan,
}
