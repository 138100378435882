import type { WaresToDepotFilters, WareToDepot } from '@models/ware'
import type { AxiosError } from 'axios'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import { waresApi } from '.'
import { waresKeys } from './constants'

interface UseWaresToDepotData {
  wares: WareToDepot[]
  waresMap: Map<string, WareToDepot>
}

export const selectData = (
  infiniteData: InfiniteData<WareToDepot[]>,
): InfiniteData<UseWaresToDepotData> => {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map(data => ({
      wares: data,
      waresMap: new Map(data.map(ware => [ware.id, ware])),
    })),
  }
}

const useWaresToDepot = (filters: WaresToDepotFilters) => {
  const query = useInfiniteQuery<
    WareToDepot[],
    AxiosError,
    UseWaresToDepotData
  >(
    waresKeys.waresToDepot(filters),
    ({ pageParam }) =>
      waresApi.getWaresToDepot({ ...filters, cursor: pageParam }),
    {
      select: selectData,
      enabled: !!filters.companyId,
      getNextPageParam: lastPage =>
        lastPage.length > 0 ? lastPage[lastPage.length - 1].id : undefined,
    },
  )

  return query
}

export default useWaresToDepot
