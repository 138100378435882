import type { UserRole } from '@models/user'
import { keyToUrl } from '@tools/common'

export const usersKeys = {
  root: (companyId?: string) => ['users', companyId ?? null] as const,
  user: (id: string) => [...usersKeys.root(), id] as const,
}

export const usersUrls = {
  root: keyToUrl(usersKeys.root()),
  user: (id: string) => keyToUrl(usersKeys.user(id)),
}

export const USER_ROLES: Readonly<UserRole[]> = [
  'RINKAI_OWNER',
  'RINKAI_MANAGER',
  'COMPANY_SUPER_ADMIN',
  'COMPANY_REGIONAL_MANAGER',
  'COMPANY_MANAGER',
  'COMPANY_DISPATCHER',
  'COMPANY_DRIVER',
]

export const AVAILABLE_ROLES: Readonly<UserRole[]> = [
  'COMPANY_SUPER_ADMIN',
  'COMPANY_DISPATCHER',
]

export const getAvaibleUserRoles = (role: UserRole) =>
  USER_ROLES.slice(USER_ROLES.indexOf(role) + 1).filter(role =>
    AVAILABLE_ROLES.includes(role),
  )
