import type { Order, OrderFilters } from '@models/order'
import type { AxiosError } from 'axios'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

interface UseOrdersProps {
  orders: Order[]
  ordersMap: Map<string, Order>
}

export const selectData = (
  infiniteData: InfiniteData<Order[]>,
): InfiniteData<UseOrdersProps> => {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map(data => ({
      orders: data,
      ordersMap: new Map(data.map(order => [order.id, order])),
    })),
  }
}

const useOrders = (filters: OrderFilters) => {
  const query = useInfiniteQuery<Order[], AxiosError, UseOrdersProps>(
    ordersKeys.orders(filters),
    ({ pageParam }) => ordersApi.getOrders({ ...filters, cursor: pageParam }),
    {
      select: selectData,
      enabled: !!filters.companyId,
      getNextPageParam: lastPage =>
        lastPage.length > 0 ? lastPage[lastPage.length - 1].id : undefined,
    },
  )

  return query
}

export default useOrders
