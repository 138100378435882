import { useMutation, useQueryClient } from 'react-query'
import companyApi from './actions'
import { companiesKeys } from './constants'

const useCompaniesMutations = () => {
  const queryClient = useQueryClient()

  const createCompany = useMutation(companyApi.createCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(companiesKeys.root)
    },
  })

  const updateCompany = useMutation(companyApi.updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(companiesKeys.root)
    },
  })

  return { createCompany, updateCompany }
}

export default useCompaniesMutations
