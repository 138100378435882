import type { Order, OrderFromErpFilters } from '@models/order'
import type { AxiosError } from 'axios'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

interface UseErpOrdersProps {
  orders: Order[]
  ordersMap: Map<string, Order>
}

export const selectData = (
  infiniteData: InfiniteData<Order[]>,
): InfiniteData<UseErpOrdersProps> => {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map(data => ({
      orders: data,
      ordersMap: new Map(data.map(order => [order.id, order])),
    })),
  }
}

const useErpOrders = (filters: OrderFromErpFilters) => {
  const query = useInfiniteQuery<Order[], AxiosError, UseErpOrdersProps>(
    ordersKeys.erpOrders(filters),
    ({ pageParam }) =>
      ordersApi.getErpOrders({ ...filters, cursor: pageParam }),
    {
      select: selectData,
      getNextPageParam: lastPage =>
        lastPage.length > 0 ? lastPage[lastPage.length - 1].id : undefined,
    },
  )

  return query
}

export default useErpOrders
