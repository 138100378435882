import type {
  OrderFilters,
  OrderFromErpFilters,
  OrderFromTmsFilters,
  OrderShortStatus,
} from '@models/order'
import { keyToUrl } from '@tools/common'
import { omit } from 'lodash'

export const ordersKeys = {
  root: ['orders'] as const,
  orders: (filters?: OrderFilters) =>
    [
      ...ordersKeys.root,
      omit(filters, ['take', 'cursor']),
      { take: filters?.take, cursor: filters?.cursor },
    ] as const,
  tmsOrders: (filters?: OrderFromTmsFilters) =>
    [
      ...ordersKeys.root,
      'tms',
      omit(filters, ['take', 'cursor']),
      { take: filters?.take, cursor: filters?.cursor },
    ] as const,
  erpOrders: (filters?: OrderFromErpFilters) =>
    [
      ...ordersKeys.root,
      'erp',
      omit(filters, ['take', 'cursor']),
      { take: filters?.take, cursor: filters?.cursor },
    ] as const,
  order: (id: string) => [...ordersKeys.root, id] as const,

  actions: () => [...ordersKeys.root, 'actions'] as const,
  cancelOrder: (id: string) => [...ordersKeys.root, id, 'cancel'] as const,
  acceptWaresToDepot: () =>
    [...ordersKeys.actions(), 'acceptWaresToDepot'] as const,
}

export const ordersUrls = {
  root: keyToUrl(ordersKeys.root),
  orders: () => keyToUrl(ordersKeys.orders()),
  erpOrders: keyToUrl(ordersKeys.erpOrders()),
  tmsOrders: keyToUrl(ordersKeys.tmsOrders()),
  order: (id: string) => keyToUrl(ordersKeys.order(id)),
  acceptWaresToDepot: keyToUrl(ordersKeys.acceptWaresToDepot()),
  cancelOrder: (id: string) => keyToUrl(ordersKeys.cancelOrder(id)),
}

export const ORDER_SHORT_STATUSES: Readonly<OrderShortStatus[]> = [
  'NOT_PROCESSED',
  'DELIVERY',
  'NOT_ACCEPTED',
  'DELIVERED',
  'NOT_DELIVERED',
  'CANCELED',
]
