import httpClient from '@api/httpClient'
import type {
  AddOperationParams,
  FinanceFilters,
  FinanceOperation,
} from '@models/finance'
import { financesUrls } from './constants'

const getFinances = async (filters?: FinanceFilters) => {
  const { data } = await httpClient.get<FinanceOperation[]>(
    financesUrls.list(filters),
    { params: filters },
  )

  return data
}

const addOperation = async (data: AddOperationParams) =>
  httpClient.post<FinanceOperation[]>(financesUrls.addFinances, data)

export default {
  getFinances,
  addOperation,
}
