import type { Ware } from '@models/ware'
import type { AxiosError } from 'axios'
import { useQuery } from 'react-query'
import waresApi from './actions'
import { waresKeys } from './constants'

interface UseWaresProps {
  wares: Ware[]
  waresMap: Map<string, Ware>
}

export const selectData = (data: Ware[]): UseWaresProps => {
  return {
    wares: data,
    waresMap: new Map(data.map(ware => [ware.id, ware])),
  }
}

const useWares = (orderId?: string) => {
  const query = useQuery<Ware[], AxiosError, UseWaresProps>(
    waresKeys.root(orderId!),
    () => waresApi.getWares(orderId!),
    {
      select: selectData,
      enabled: !!orderId,
    },
  )

  return query
}

export default useWares
