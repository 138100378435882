import httpClient from '@api/httpClient'
import type { User } from '@models/user'
import { usersUrls } from './constants'

const getUsers = async (companyId: string) => {
  const { data } = await httpClient.get<User[]>(usersUrls.root, {
    params: { companyId },
  })
  return data
}

const getUser = async (id: string) => {
  const { data } = await httpClient.get<User>(usersUrls.user(id))
  return data
}

const createUser = async (props: Partial<User>) => {
  const { data } = await httpClient.post<User>(usersUrls.root, props)
  return data
}

const updateUser = async ({ id, ...props }: Partial<User>) => {
  const { data } = await httpClient.put<User>(usersUrls.user(id!), props)
  return data
}

const deleteUser = async (id: string) => {
  await httpClient.delete<User>(usersUrls.user(id))
}

export default {
  getUsers,
  getUser,
  createUser,
  updateUser,
  deleteUser,
}
