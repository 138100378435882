import type { Route, RouteFilters } from '@models/route'
import type { AxiosError } from 'axios'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import routesApi from './actions'
import { routesKeys } from './constants'

interface UseRoutesData {
  routes: Route[]
  routesMap: Map<string, Route>
}

export const selectData = (
  infiniteData: InfiniteData<Route[]>,
): InfiniteData<UseRoutesData> => {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map(data => ({
      routes: data,
      routesMap: new Map(data.map(route => [route.id, route])),
    })),
  }
}

const useRoutes = (filters: RouteFilters) => {
  const query = useInfiniteQuery<Route[], AxiosError, UseRoutesData>(
    routesKeys.list(filters),
    ({ pageParam }) => routesApi.getRoutes({ ...filters, cursor: pageParam }),
    {
      select: selectData,
      enabled: !!filters.companyId,
      getNextPageParam: lastPage =>
        lastPage.length > 0 ? lastPage[lastPage.length - 1].id : undefined,
    },
  )

  return query
}

export default useRoutes
