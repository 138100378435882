import type {
  FinanceFilters,
  FinanceOperation,
  UseFinanceData,
} from '@models/finance'
import type { AxiosError } from 'axios'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import financesApi from './actions'
import { financesKeys } from './constants'

export const selectData = (
  infiniteData: InfiniteData<FinanceOperation[]>,
): InfiniteData<UseFinanceData> => ({
  ...infiniteData,
  pages: infiniteData.pages.map(data => ({
    finances: data,
    financesMap: new Map(data.map(finance => [finance.id, finance])),
  })),
})

const useFinances = (filters: FinanceFilters) => {
  const query = useInfiniteQuery<
    FinanceOperation[],
    AxiosError,
    UseFinanceData
  >(
    financesKeys.list(filters),
    ({ pageParam }) =>
      financesApi.getFinances({
        ...filters,
        cursor: pageParam,
        take: 10,
      }),
    {
      select: selectData,
      getNextPageParam: lastPage =>
        lastPage.length > 0 ? lastPage[lastPage.length - 1].id : undefined,
    },
  )

  return query
}

export default useFinances
