import type { Order, OrderFromTmsFilters } from '@models/order'
import type { AxiosError } from 'axios'
import { InfiniteData, useInfiniteQuery } from 'react-query'
import ordersApi from './actions'
import { ordersKeys } from './constants'

interface UseTmsOrdersProps {
  orders: Order[]
  ordersMap: Map<string, Order>
}

export const selectData = (
  infiniteData: InfiniteData<Order[]>,
): InfiniteData<UseTmsOrdersProps> => {
  return {
    ...infiniteData,
    pages: infiniteData.pages.map(data => ({
      orders: data,
      ordersMap: new Map(data.map(order => [order.id, order])),
    })),
  }
}

const useTmsOrders = (filters: OrderFromTmsFilters) => {
  const query = useInfiniteQuery<Order[], AxiosError, UseTmsOrdersProps>(
    ordersKeys.tmsOrders(filters),
    ({ pageParam }) =>
      ordersApi.getTmsOrders({ ...filters, cursor: pageParam }),
    {
      select: selectData,
      getNextPageParam: lastPage =>
        lastPage.length > 0 ? lastPage[lastPage.length - 1].id : undefined,
    },
  )

  return query
}

export default useTmsOrders
